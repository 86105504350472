.dot {
  border: 1px solid #fff;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 2px;
  margin-left: 2px;
}

.dotActive {
  background: #fff;
}

